.QRGenerator {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1em;
}

.paymentQR {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  scale: 1.2;
}

.QRCodeViewer {
  border: 12px solid black;
  width: 250px;
  height: 250px;
  border-radius: 18px;
  margin-bottom: 2em;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.QRCodeViewer svg {
  transform: scale(0.95);
}

.paymentLogo {
  height: 6rem;
}